th {
  font-size: 12px;    
  width: auto;
}
td {
  font-size: 15px;  
  vertical-align: middle;
}
.action-column {
  width: auto;
  text-align: right;
}
.actions-buttons {
  text-align: right;
}